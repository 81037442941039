import React from "react";
import { motion } from "framer-motion";
import { useParams, Link } from "react-router-dom";
import AnimatedBadge from "../Homepage/AnimatedBadge";
import Background from "../Homepage/background";
import { Helmet } from "react-helmet-async"; // Import from react-helmet-async

const GlossaryTermPage = () => {
  const { term } = useParams();

  const termDetails = {
    blockchain: {
      title: "What is Blockchain?",
      definition: "A blockchain is a decentralized, distributed ledger technology that records transactions across many computers.",
      details: [
        "Decentralized: No single entity has control over the entire network.",
        "Distributed: The ledger is spread across multiple nodes or computers.",
        "Immutable: Once data is recorded, it's extremely difficult to change.",
        "Transparent: All transactions are visible to anyone on the network.",
        "Secure: Uses cryptography to ensure data integrity and security."
      ],
      examples: [
        "Bitcoin uses blockchain to record financial transactions.",
        "Ethereum uses blockchain to support smart contracts and decentralized applications.",
        "Supply chain management can use blockchain to track products from manufacture to delivery."
      ]
    },
    bitcoin: {
      title: "What is Bitcoin?",
      definition: "Bitcoin is the first and most well-known cryptocurrency, created by an anonymous person or group under the pseudonym Satoshi Nakamoto.",
      details: [
        "Decentralized: Not controlled by any central authority or government.",
        "Limited Supply: Only 21 million bitcoins will ever exist.",
        "Pseudonymous: Transactions are linked to addresses, not personal identities.",
        "Peer-to-Peer: Transactions occur directly between users without intermediaries.",
        "Blockchain-based: Uses blockchain technology to record all transactions."
      ],
      examples: [
        "Used as a store of value, often referred to as 'digital gold'.",
        "Can be used for online purchases at businesses that accept Bitcoin.",
        "Facilitates cross-border transactions without traditional banking fees."
      ]
    },
    ethereum: {
      title: "What is Ethereum?",
      definition: "Ethereum is a decentralized, open-source blockchain featuring smart contract functionality.",
      details: [
        "Smart Contracts: Self-executing contracts with the terms directly written into code.",
        "Ether: The native cryptocurrency of the Ethereum network.",
        "Decentralized Applications (dApps): Applications that run on the Ethereum network.",
        "Ethereum Virtual Machine (EVM): The runtime environment for smart contracts in Ethereum.",
        "Gas: The fee required to conduct a transaction or execute a contract on Ethereum."
      ],
      examples: [
        "Creating and trading non-fungible tokens (NFTs).",
        "Decentralized finance (DeFi) applications like lending and borrowing platforms.",
        "Decentralized autonomous organizations (DAOs) for collective decision-making."
      ]
    },
    defi: {
      title: "What is DeFi?",
      definition: "DeFi, or Decentralized Finance, refers to financial services built on blockchain technologies, primarily the Ethereum network.",
      details: [
        "Permissionless: Anyone can access DeFi services without needing approval from a centralized authority.",
        "Transparent: All transactions and smart contracts are visible on the blockchain.",
        "Interoperable: Different DeFi protocols can often work together seamlessly.",
        "Non-custodial: Users maintain control of their assets rather than trusting them to a third party.",
        "Programmable: Smart contracts automate financial services and create new possibilities."
      ],
      examples: [
        "Decentralized exchanges (DEXs) like Uniswap for trading cryptocurrencies.",
        "Lending and borrowing platforms like Aave or Compound.",
        "Stablecoins pegged to the value of fiat currencies, like DAI."
      ]
    },
    nft: {
      title: "What are NFTs?",
      definition: "NFT stands for Non-Fungible Token, a unique digital asset verified using blockchain technology.",
      details: [
        "Unique: Each NFT has distinct information that sets it apart from any other NFT.",
        "Indivisible: NFTs cannot be divided into smaller denominations.",
        "Provable Scarcity: The blockchain verifies the rarity and ownership of NFTs.",
        "Programmable: NFTs can include complex functionalities through smart contracts.",
        "Interoperable: NFTs can often be used across various platforms and applications."
      ],
      examples: [
        "Digital art pieces sold as NFTs, like Beeple's $69 million artwork.",
        "Virtual real estate in blockchain-based games like Decentraland.",
        "Collectible sports moments, such as NBA Top Shot highlights."
      ]
    },
    "onchain-data": {
        title: "What is On-Chain Data?",
        definition: "On-chain data refers to information that is stored directly on a blockchain network and can be verified by anyone with access to that network.",
        details: [
          "Transparent: All on-chain data is publicly visible and verifiable.",
          "Immutable: Once recorded, on-chain data cannot be altered without consensus.",
          "Real-time: On-chain data provides up-to-the-minute information about network activity.",
          "Trustless: No need to rely on third-party sources for data verification."
        ],
        examples: [
          "Transaction histories and balances of blockchain addresses.",
          "Smart contract code and interactions on platforms like Ethereum.",
          "Token transfers and ownership records."
        ]
      },
      "blockchain-analytics": {
        title: "What are Blockchain Analytics?",
        definition: "Blockchain analytics involves the analysis of on-chain data to derive insights about network activity, user behavior, and market trends.",
        details: [
          "Pattern Recognition: Identifying trends and anomalies in blockchain data.",
          "Forensics: Tracing the flow of funds across addresses and time.",
          "Risk Assessment: Evaluating the risk profile of addresses or transactions.",
          "Market Intelligence: Deriving trading and investment insights from on-chain activity."
        ],
        examples: [
          "Chainalysis and CipherTrace for cryptocurrency transaction monitoring.",
          "Glassnode and IntoTheBlock for market intelligence based on on-chain metrics.",
          "Etherscan for Ethereum blockchain exploration and analysis."
        ]
      },
      "crypto-analytics": {
        title: "What are Crypto Analytics?",
        definition: "Crypto analytics encompasses the analysis of both on-chain and off-chain data related to cryptocurrencies to inform investment decisions and understand market dynamics.",
        details: [
          "Price Analysis: Studying historical and real-time price movements.",
          "Sentiment Analysis: Gauging market sentiment through social media and news.",
          "On-Chain Metrics: Analyzing blockchain data for insights.",
          "Wallet Profiling: Identifying and categorizing significant market players."
        ],
        examples: [
          "CoinMetrics for comprehensive crypto asset analytics.",
          "Santiment for behavior analytics of crypto markets.",
          "LunarCrush for social media sentiment analysis in crypto."
        ]
      },
      "ema": {
        title: "What is EMA in Crypto?",
        definition: "EMA stands for Exponential Moving Average, a type of moving average that gives more weight to recent price data. It's commonly used in crypto trading for identifying trends and potential entry or exit points.",
        details: [
          "Responsive: Reacts more quickly to recent price changes than simple moving averages.",
          "Trend Indicator: Helps identify the direction and strength of trends.",
          "Support/Resistance: Often acts as dynamic support or resistance levels.",
          "Crossovers: EMA crossovers are used as trading signals."
        ],
        examples: [
          "50-day and 200-day EMAs are often used to identify long-term trends in Bitcoin.",
          "Traders might use EMA crossovers, like when a short-term EMA crosses above a long-term EMA, as a buy signal.",
          "The 20-day EMA is popular for short-term trend analysis in crypto markets."
        ]
      },
      "utxo": {
        title: "What is UTXO?",
        definition: "UTXO stands for Unspent Transaction Output. It's a model used by some cryptocurrencies, including Bitcoin, to track and validate transactions.",
        details: [
          "Discrete: Each UTXO represents a specific amount of cryptocurrency.",
          "Immutable: Once created, a UTXO cannot be altered, only spent entirely.",
          "Traceable: Allows for easy tracking of transaction history.",
          "Scalability: Can potentially offer better scalability than account-based models."
        ],
        examples: [
          "In Bitcoin, when you receive BTC, it's stored as one or more UTXOs in your wallet.",
          "When spending Bitcoin, you're actually sending one or more entire UTXOs.",
          "Change from a transaction creates a new UTXO with the remaining balance."
        ]
      },
      "smart-contract": {
        title: "What is a Smart Contract?",
        definition: "A smart contract is a self-executing contract with the terms of the agreement directly written into code. It automatically enforces and executes the terms when predetermined conditions are met.",
        details: [
          "Autonomous: Executes automatically without intermediaries.",
          "Transparent: Code and execution results are visible on the blockchain.",
          "Immutable: Once deployed, the contract code cannot be changed.",
          "Trustless: Parties don't need to trust each other, just the code."
        ],
        examples: [
          "Automated token swaps on decentralized exchanges like Uniswap.",
          "Decentralized lending protocols like Aave or Compound.",
          "NFT minting and trading contracts."
        ]
      },
      "hash-rate": {
        title: "What is Hash Rate?",
        definition: "Hash rate refers to the speed at which a computer or network is completing hash operations in cryptocurrency mining. It's a measure of computational power and mining difficulty.",
        details: [
          "Network Security: Higher hash rates generally mean more secure networks.",
          "Mining Difficulty: Hash rate directly affects mining difficulty adjustments.",
          "Performance Metric: Used to compare mining hardware efficiency.",
          "Network Health Indicator: Often used to gauge the overall health of a blockchain network."
        ],
        examples: [
          "Bitcoin's network hash rate is often used as an indicator of network security and miner confidence.",
          "ASIC miners are designed to achieve high hash rates for specific algorithms.",
          "Cloud mining services often sell hash rate to customers."
        ]
      },
      "consensus-mechanism": {
        title: "What is a Consensus Mechanism?",
        definition: "A consensus mechanism is a protocol that ensures all nodes in a blockchain network agree on the validity of transactions and the order in which they are added to the blockchain.",
        details: [
          "Agreement: Ensures all nodes have the same version of the blockchain.",
          "Security: Protects against attacks and fraudulent transactions.",
          "Decentralization: Allows for trustless operation without central authority.",
          "Finality: Determines when a transaction is considered irreversible."
        ],
        examples: [
          "Proof of Work (PoW) used by Bitcoin and (currently) Ethereum.",
          "Proof of Stake (PoS) used by Cardano and (soon) Ethereum 2.0.",
          "Delegated Proof of Stake (DPoS) used by EOS."
        ]
      },
      "gas-fee": {
        title: "What is a Gas Fee?",
        definition: "A gas fee is the cost required to perform a transaction or execute a contract on the Ethereum blockchain. It's paid in Ether and is used to compensate miners for the computational resources required to process and validate transactions.",
        details: [
          "Variable: Gas fees fluctuate based on network congestion.",
          "Customizable: Users can set gas prices to prioritize their transactions.",
          "Necessary: Required for all transactions and smart contract interactions.",
          "Burn Mechanism: In Ethereum's EIP-1559, a portion of gas fees is burned."
        ],
        examples: [
          "Sending ETH from one address to another requires a gas fee.",
          "Interacting with a DeFi protocol like Uniswap incurs gas fees.",
          "Minting an NFT on Ethereum requires paying gas fees."
        ]
      },
      "liquidity-pool": {
        title: "What is a Liquidity Pool?",
        definition: "A liquidity pool is a collection of funds locked in a smart contract, used to facilitate decentralized trading, lending, and other DeFi activities.",
        details: [
          "Automated: Enables automated market making for decentralized exchanges.",
          "Yield Generation: Liquidity providers earn fees from trades.",
          "Dual-Asset: Typically contains a pair of tokens in equal value.",
          "Slippage: Larger pools generally offer lower slippage for trades."
        ],
        examples: [
          "Uniswap's ETH/USDC pool for swapping between ETH and USDC.",
          "Curve Finance's stablecoin pools for low-slippage stablecoin swaps.",
          "Balancer's multi-asset pools for more complex token ratios."
        ]
      },
      "yield-farming": {
        title: "What is Yield Farming?",
        definition: "Yield farming, also known as liquidity mining, is a way to generate rewards with cryptocurrency holdings. It usually involves lending or staking crypto assets to generate returns.",
        details: [
          "High Yields: Can offer significantly higher returns than traditional finance.",
          "Risky: Often involves complex strategies and exposure to multiple protocols.",
          "Dynamic: Yields can change rapidly based on market conditions.",
          "Composable: Yields can be reinvested to compound returns."
        ],
        examples: [
          "Providing liquidity to a Uniswap pool and earning trading fees.",
          "Staking LP tokens in yield aggregators like Yearn Finance.",
          "Borrowing and lending across multiple protocols to maximize returns."
        ]
      },
      "token-burn": {
        title: "What is Token Burning?",
        definition: "Token burning is the process of permanently removing a certain amount of cryptocurrency tokens from circulation, often done to reduce supply and potentially increase value.",
        details: [
          "Deflationary: Can create scarcity and potentially increase token value.",
          "Verifiable: Burns are typically done by sending tokens to an unspendable address.",
          "Strategic: Often used as part of tokenomics models or governance decisions.",
          "Permanent: Burned tokens cannot be recovered or reused."
        ],
        examples: [
          "Binance's BNB quarterly burns based on trading volume.",
          "Ethereum's EIP-1559 implementation that burns a portion of transaction fees.",
          "Project-specific burns to adjust token supply or distribution."
        ]
      },
      "stablecoin": {
        title: "What is a Stablecoin?",
        definition: "A stablecoin is a type of cryptocurrency designed to maintain a stable value relative to a reference asset, often a fiat currency like the US dollar.",
        details: [
          "Stability: Aims to minimize price volatility.",
          "Pegged: Usually pegged to a fiat currency, but can be pegged to other assets.",
          "Collateralized: Often backed by reserves of the pegged asset or other cryptocurrencies.",
          "Use Cases: Used for trading, savings, and as a medium of exchange."
        ],
        examples: [
          "USDT (Tether) and USDC, fiat-collateralized stablecoins pegged to the US dollar.",
          "DAI, an over-collateralized, crypto-backed stablecoin.",
          "AMPL (Ampleforth), an algorithmic stablecoin that adjusts supply to maintain price."
        ]
      },
      "cold-storage": {
        title: "What is Cold Storage?",
        definition: "Cold storage in cryptocurrency refers to keeping a reserve of cryptocurrency offline. This is done to protect the stored cryptocurrency from hacking, theft, or accidental loss.",
        details: [
          "Security: Offers high security by keeping private keys offline.",
          "Long-term: Ideal for long-term storage of large amounts of crypto.",
          "Hardware Wallets: Often implemented using specialized hardware devices.",
          "Paper Wallets: Can also be implemented using physical paper or metal storage."
        ],
        examples: [
          "Ledger and Trezor hardware wallets for cold storage.",
          "Paper wallets containing printed private keys and addresses.",
          "Air-gapped computers used for generating and storing keys offline."
        ]
      },
      "dex": {
        title: "What is a DEX?",
        definition: "A DEX, or Decentralized Exchange, is a type of cryptocurrency exchange that operates without a central authority and allows for direct peer-to-peer cryptocurrency transactions.",
        details: [
          "Non-Custodial: Users maintain control of their funds.",
          "Permissionless: Anyone can use a DEX without approval or KYC.",
          "Smart Contract Based: Operates using blockchain smart contracts.",
          "Liquidity Pools: Often uses automated market makers and liquidity pools."
        ],
        examples: [
          "Uniswap, a popular Ethereum-based DEX using an automated market maker model.",
          "dYdX, a DEX focused on cryptocurrency derivatives trading.",
          "PancakeSwap, a DEX on the Binance Smart Chain."
        ]
      },
      "tokenomics": {
        title: "What are Tokenomics?",
        definition: "Tokenomics refers to the economic model and design principles of a cryptocurrency or token. It encompasses factors like supply, distribution, and utility that influence a token's value and use within its ecosystem.",
        details: [
          "Supply: Includes total supply, circulating supply, and emission schedule.",
          "Distribution: How tokens are allocated among stakeholders.",
          "Utility: The token's use cases within its ecosystem.",
          "Incentives: How the token model incentivizes desired behaviors."
        ],
        examples: [
          "Bitcoin's fixed supply cap and halving events.",
          "Ethereum's shift from PoW to PoS and its impact on ETH issuance.",
          "Governance tokens in DAOs that confer voting rights."
        ]
      },
      "layer-2": {
        title: "What is a Layer 2 Solution?",
        definition: "Layer 2 refers to a secondary framework or protocol built on top of an existing blockchain system. Its main goal is to solve the transaction speed and scaling difficulties faced by major cryptocurrency networks.",
        details: [
          "Scalability: Increases transaction throughput of the main chain.",
          "Lower Fees: Often results in reduced transaction costs.",
          "Security: Relies on the security of the underlying blockchain.",
          "Variety: Includes solutions like state channels, sidechains, and rollups."
        ],
        examples: [
          "Bitcoin's Lightning Network for fast, low-cost BTC transactions.",
          "Ethereum's Optimism and Arbitrum, which use optimistic rollups.",
          "Polygon, a sidechain and commit chain scaling solution for Ethereum."
        ]
      },
      "dao": {
        title: "What is a DAO?",
        definition: "A DAO, or Decentralized Autonomous Organization, is an organization represented by rules encoded as a computer program that is transparent, controlled by the organization members, and not influenced by a central government.",
        details: [
          "Decentralized: No central authority; decisions made by community.",
          "Autonomous: Operates via smart contracts without human intervention.",
          "Transparent: All rules and financial transactions are public.",
          "Token-based: Often uses tokens for governance and decision-making."
        ],
        examples: [
          "MakerDAO, which governs the DAI stablecoin.",
          "Uniswap's governance DAO for protocol upgrades and treasury management.",
          "Compound's governance system for setting protocol parameters."
        ]
      },
      "nonce": {
        title: "What is a Nonce in Blockchain?",
        definition: "In blockchain technology, a nonce (number only used once) is a random or semi-random number that's used in authentication protocols to ensure old communications cannot be reused in replay attacks.",
        details: [
          "Mining: In proof-of-work, miners change the nonce to get different hash results.",
          "Unique: Ensures each transaction or block is unique.",
          "Security: Helps prevent double-spending and replay attacks.",
          "Sequence: In account-based models, it's used to ensure transaction order."
        ],
        examples: [
          "In Bitcoin mining, changing the nonce to find a hash that meets difficulty requirements.",
          "Ethereum uses nonces to ensure the order of transactions from a single address.",
          "In cryptographic communication, nonces are used to prevent replay attacks."
        ]
      },
      "mempool": {
        title: "What is a Mempool?",
        definition: "A mempool (memory pool) is a cryptocurrency node's mechanism for storing information on unconfirmed transactions. It acts as a sort of waiting room for transactions that have been broadcast to the network but not yet included in a block.",
        details: [
          "Temporary: Transactions stay in the mempool until confirmed or dropped.",
          "Variable Size: The size of the mempool can fluctuate based on network activity.",
          "Fee Priority: Transactions with higher fees are typically prioritized.",
          "Network Congestion Indicator: A large mempool can indicate network congestion."
        ],
        examples: [
          "During times of high activity, Bitcoin's mempool can become congested, leading to longer wait times for low-fee transactions.",
          "Some wallet software allows users to view the current state of the mempool to estimate appropriate transaction fees.",
          "Mempool visualization tools can help traders and users understand current network conditions."
        ]
      },
      "atomic-swap": {
        title: "What is an Atomic Swap?",
        definition: "An atomic swap is a smart contract technology that enables the exchange of one cryptocurrency for another without using centralized intermediaries, such as exchanges.",
        details: [
          "Trustless: No need to trust a third party with your funds.",
          "Cross-chain: Allows exchanges between different blockchain networks.",
          "Decentralized: Occurs peer-to-peer without centralized control.",
          "Time-bound: Includes time locks to ensure the trade completes or fully refunds."
        ],
        examples: [
          "Swapping Bitcoin for Litecoin directly between user wallets.",
          "Cross-chain DEXs that utilize atomic swaps for trading.",
          "Lightning Network's submarine swaps for off-chain to on-chain exchanges."
        ]
      },
      "hard-fork": {
        title: "What is a Hard Fork?",
        definition: "A hard fork is a radical change to a network's protocol that makes previously invalid blocks and transactions valid, or vice-versa. It requires all nodes or users to upgrade to the latest version of the protocol software.",
        details: [
          "Backward Incompatible: Creates a permanent divergence from the previous version.",
          "New Chain: Often results in two separate blockchains and cryptocurrencies.",
          "Community Split: Can lead to ideological and economic divisions in the community.",
          "Planned or Contentious: Can be pre-planned upgrades or result from disagreements."
        ],
        examples: [
          "The creation of Bitcoin Cash from Bitcoin in August 2017.",
          "Ethereum's transition to Ethereum 2.0 (now called 'The Merge').",
          "Monero's regular hard forks to implement privacy and security upgrades."
        ]
      },
      "multisig": {
        title: "What is a Multisig Wallet?",
        definition: "A multisig (multi-signature) wallet is a type of cryptocurrency wallet that requires two or more private keys to sign and send a transaction, adding an extra layer of security.",
        details: [
          "Enhanced Security: Protects against the compromise of a single key.",
          "Shared Control: Allows multiple parties to have control over funds.",
          "Customizable: Can be set up with various configurations (e.g., 2-of-3, 3-of-5).",
          "Smart Contract Based: Often implemented using smart contracts on platforms like Ethereum."
        ],
        examples: [
          "Corporate treasuries using 3-of-5 multisig wallets for fund management.",
          "Escrow services implemented with 2-of-3 multisig arrangements.",
          "Personal use of 2-of-2 multisig for enhanced security of large holdings."
        ]
      },
      "zero-knowledge-proof": {
        title: "What is a Zero-Knowledge Proof?",
        definition: "A zero-knowledge proof is a cryptographic method by which one party can prove to another party that they know a value X, without conveying any information apart from the fact that they know the value X.",
        details: [
          "Privacy: Allows verification without revealing sensitive information.",
          "Efficiency: Can significantly reduce the amount of data needed for verification.",
          "Versatility: Applicable in various scenarios beyond cryptocurrencies.",
          "Complexity: Implementing secure zero-knowledge proofs can be challenging."
        ],
        examples: [
          "Zcash's use of zk-SNARKs for private transactions.",
          "Ethereum's implementation of zk-rollups for scaling.",
          "Identity verification systems that don't reveal personal data."
        ]
      },
      "amm": {
        title: "What is an AMM?",
        definition: "An AMM, or Automated Market Maker, is a decentralized asset trading model that uses a mathematical formula to price assets. It allows digital assets to be traded without permission and automatically by using liquidity pools instead of a traditional market of buyers and sellers.",
        details: [
          "Liquidity Pools: Uses pools of tokens to facilitate trades.",
          "Constant Product Formula: Most common AMMs use x * y = k formula.",
          "Passive Market Making: Liquidity providers can earn fees without active trading.",
          "Slippage: Large trades can result in significant price slippage."
        ],
        examples: [
          "Uniswap's x * y = k model for token swaps.",
          "Curve Finance's stable swap AMM optimized for similar assets.",
          "Balancer's generalized n-token pool AMM."
        ]
      },
      "bridge": {
        title: "What is a Blockchain Bridge?",
        definition: "A blockchain bridge, also known as a cross-chain bridge, is a connection that allows the transfer of tokens and/or arbitrary data from one blockchain network to another.",
        details: [
          "Interoperability: Enables communication between otherwise isolated blockchains.",
          "Asset Transfer: Allows tokens to be used on non-native blockchains.",
          "Liquidity: Can help in distributing liquidity across different networks.",
          "Security Risks: Bridges can be targets for attacks due to large amounts of locked assets."
        ],
        examples: [
          "Wrapped Bitcoin (WBTC) on Ethereum, allowing Bitcoin to be used in Ethereum's DeFi ecosystem.",
          "Polygon's PoS Bridge for moving assets between Ethereum and Polygon.",
          "Multichain (formerly AnySwap) for cross-chain transactions across multiple networks."
        ]
      },
      "oracle": {
        title: "What is a Blockchain Oracle?",
        definition: "A blockchain oracle is a third-party service that provides smart contracts with external information. They act as a bridge between blockchains and the outside world.",
        details: [
          "Data Feed: Provides real-world data to on-chain smart contracts.",
          "Crucial for DeFi: Many DeFi applications rely on oracles for price feeds.",
          "Types: Include software oracles, hardware oracles, consensus-based oracles, etc.",
          "Oracle Problem: The challenge of ensuring the reliability and trustworthiness of external data."
        ],
        examples: [
          "Chainlink's decentralized oracle networks providing price data to DeFi protocols.",
          "MakerDAO's use of oracles to determine the price of ETH for CDP liquidations.",
          "Sports betting dApps using oracles to fetch real-world game results."
        ]
      },
      "gas-optimization": {
        title: "What is Gas Optimization?",
        definition: "Gas optimization refers to the practice of minimizing the computational resources required for executing transactions or smart contracts on a blockchain, typically Ethereum, to reduce transaction costs.",
        details: [
          "Cost Efficiency: Reduces the cost of interacting with the blockchain.",
          "Performance: Can improve the speed and efficiency of dApps.",
          "Complexity: Often involves trade-offs between readability and efficiency.",
          "Dynamic: Optimization strategies may change with network upgrades."
        ],
        examples: [
          "Using uint8 instead of uint256 when smaller integers are sufficient.",
          "Optimizing loop operations in smart contracts.",
          "Batching multiple transactions into a single transaction."
        ]
      },
      "slippage": {
        title: "What is Slippage in Crypto Trading?",
        definition: "Slippage refers to the difference between the expected price of a trade and the price at which the trade is actually executed. It often occurs in high-volatility markets or for large-volume trades.",
        details: [
          "Market Dynamics: More common in illiquid or volatile markets.",
          "Size Dependent: Larger trades are more likely to experience slippage.",
          "Positive or Negative: Can result in better or worse prices than expected.",
          "DEX Feature: Common in decentralized exchanges due to their AMM nature."
        ],
        examples: [
          "Setting a 1% slippage tolerance when swapping tokens on Uniswap.",
          "Large Bitcoin trades on exchanges moving the market price.",
          "Using slippage protection features in DeFi aggregators like 1inch."
        ]
      }
  };

  const currentTerm = termDetails[term.toLowerCase()] || {
    title: `What is ${term}?`,
    definition: "We're still working on a detailed explanation for this term. Check back soon!",
    details: [],
    examples: [],
    extendedContent: "We're currently developing more in-depth content for this term. Please check back later for a comprehensive explanation.",
  };

  return (
    <>
      <Helmet>
        <title>{currentTerm.title} | Crypto Glossary</title>
        <meta name="description" content={currentTerm.definition} />
        <meta name="keywords" content={`${term}, cryptocurrency, blockchain, crypto glossary`} />
        <link rel="canonical" href={`https://yourdomain.com/glossary/${term.toLowerCase()}`} />
        <meta property="og:title" content={`${currentTerm.title} | Crypto Glossary`} />
        <meta property="og:description" content={currentTerm.definition} />
        <meta property="og:url" content={`https://yourdomain.com/glossary/${term.toLowerCase()}`} />
        <meta property="og:type" content="article" />
      </Helmet>

      <div className="relative pt-10 pb-20 sm:py-24 z-0 overflow-hidden bg-white">
        <Background />
        <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0.0, y: 40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              delay: 0.2,
              duration: 0.7,
              ease: "easeInOut",
            }}
            className="text-center"
          >
            <Link to="/Glossary" className="inline-block mb-6">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-primary text-white font-bold py-2 px-4 rounded-full shadow-lg hover:bg-primary-dark transition duration-300"
              >
                ← Back to Glossary
              </motion.button>
            </Link>

            <h1 className="mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
              {currentTerm.title}
            </h1>
            <p className="mx-auto mt-3 max-w-md text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-3xl md:text-xl">
              {currentTerm.definition}
            </p>
          </motion.div>

          <div className="mt-16">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200"
            >
              {currentTerm.details.length > 0 && (
                <div className="px-4 py-5 sm:p-6">
                  <h2 className="text-2xl leading-6 font-medium text-gray-900">Key Details</h2>
                  <div className="mt-5">
                    <ul className="list-disc pl-5 space-y-1">
                      {currentTerm.details.map((detail, index) => (
                        <li key={index} className="text-base text-gray-500">{detail}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              {currentTerm.examples.length > 0 && (
                <div className="px-4 py-5 sm:p-6">
                  <h2 className="text-2xl leading-6 font-medium text-gray-900">Examples</h2>
                  <div className="mt-5">
                    <ul className="list-disc pl-5 space-y-1">
                      {currentTerm.examples.map((example, index) => (
                        <li key={index} className="text-base text-gray-500">{example}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </motion.div>
          </div>

          {/* Extended content section */}
          <div className="mt-16">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="bg-white overflow-hidden shadow rounded-lg"
            >
              <div className="px-4 py-5 sm:p-6">
                <h2 className="text-2xl leading-6 font-medium text-gray-900 mb-4">Deep Dive into {currentTerm.title}</h2>
                <div className="prose prose-lg text-gray-500 max-w-none">
                  {currentTerm.extendedContent ? (
                    <div dangerouslySetInnerHTML={{ __html: currentTerm.extendedContent }} />
                  ) : (
                    <p>We're currently developing more in-depth content for this term. Please check back later for a comprehensive explanation.</p>
                  )}
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GlossaryTermPage;