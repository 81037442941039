import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowSmallRightIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import Background from "./background";
import AnimatedBadge from "./AnimatedBadge";

const GRID_BOX_SIZE = 32;
const BEAM_WIDTH_OFFSET = 1;

const AnimatedGetStartedButton = React.memo(({ onClick }) => (
  <button
    onClick={onClick}
    className={`
      relative z-0 flex items-center justify-center overflow-hidden rounded-lg
      bg-primary px-6 py-3 text-md sm:text-lg font-semibold
      text-white transition-all duration-500 shadow-md hover:shadow-lg

      before:absolute before:inset-0
      before:-z-10 before:translate-x-[150%]
      before:translate-y-[150%] before:scale-[2.5]
      before:rounded-[100%] before:bg-white
      before:transition-transform before:duration-1000
      before:content-[""]

      hover:scale-105 hover:text-primary
      hover:before:translate-x-[0%]
      hover:before:translate-y-[0%]
      active:scale-95`}
  >
    Get started
  </button>
));
AnimatedGetStartedButton.displayName = "AnimatedGetStartedButton";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    const handleResize = () =>
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

const Beam = React.memo(({ top, left, transition = {} }) => (
  <motion.div
    initial={{ y: 0, opacity: 0 }}
    animate={{ opacity: [0, 1, 0], y: 32 * 8 }}
    transition={{
      ease: "easeInOut",
      duration: 3,
      repeat: Infinity,
      repeatDelay: 1.5,
      ...transition,
    }}
    style={{ top, left }}
    className="absolute z-10 h-[64px] w-[1px] bg-gradient-to-b from-primary/0 to-primary"
  />
));
Beam.displayName = "Beam";

const Beams = React.memo(() => {
  const { width } = useWindowSize();
  const numColumns = width ? Math.floor(width / GRID_BOX_SIZE) : 0;

  const placements = useMemo(
    () => [
      {
        top: GRID_BOX_SIZE * 0,
        left: Math.floor(numColumns * 0.05) * GRID_BOX_SIZE,
        transition: { duration: 3.5, repeatDelay: 5, delay: 2 },
      },
      {
        top: GRID_BOX_SIZE * 12,
        left: Math.floor(numColumns * 0.15) * GRID_BOX_SIZE,
        transition: { duration: 3.5, repeatDelay: 10, delay: 4 },
      },
      {
        top: GRID_BOX_SIZE * 3,
        left: Math.floor(numColumns * 0.25) * GRID_BOX_SIZE,
      },
      {
        top: GRID_BOX_SIZE * 9,
        left: Math.floor(numColumns * 0.75) * GRID_BOX_SIZE,
        transition: { duration: 2, repeatDelay: 7.5, delay: 3.5 },
      },
      {
        top: 0,
        left: Math.floor(numColumns * 0.7) * GRID_BOX_SIZE,
        transition: { duration: 3, repeatDelay: 2, delay: 1 },
      },
      {
        top: GRID_BOX_SIZE * 2,
        left: Math.floor(numColumns * 1) * GRID_BOX_SIZE - GRID_BOX_SIZE,
        transition: { duration: 5, repeatDelay: 5, delay: 5 },
      },
    ],
    [numColumns]
  );

  return (
    <>
      {placements.map((p, i) => (
        <Beam
          key={i}
          top={p.top}
          left={p.left - BEAM_WIDTH_OFFSET}
          transition={p.transition || {}}
        />
      ))}
    </>
  );
});
Beams.displayName = "Beams";

const GradientGrid = React.memo(() => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 2.5, ease: "easeInOut" }}
    className="absolute inset-0 z-0"
  >
    <div
      style={{
        backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke-width='2' stroke='rgb(128 128 128 / 0.1)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e")`,
      }}
      className="absolute inset-0 z-0"
    />
    <div className="absolute inset-0 z-10 bg-gradient-to-b from-white/0 to-white" />
  </motion.div>
));
GradientGrid.displayName = "GradientGrid";

const HeroSection = () => {
  const navigate = useNavigate();
  const handleGetStarted = useCallback(
    () => navigate("/Explore?blockchain=Ethereum"),
    [navigate]
  );
  const handleLearnMore = useCallback(() => navigate("/Pricing"), [navigate]);

  return (
    <div className="relative pt-10 pb-20 sm:py-24 z-0 overflow-hidden bg-white">
      <Background />
      <motion.div
        initial={{ opacity: 0.0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          delay: 0.2,
          duration: 0.7,
          ease: "easeInOut",
        }}
        className="relative mx-auto max-w-[90rem] pb-4 flex flex-col items-center text-center font-roboto gap-4 z-10"
      >
        <AnimatedBadge href="#" newLabel="New">
          Query is live!
        </AnimatedBadge>

        <h1 className="text-4xl sm:text-5xl lg:text-6xl xl:text-7xl font-bold tracking-tight text-gray-800 mb-4 font-monda leading-tight">
          <span className="block">
            Transforming{" "}
            <span className="relative inline-block">
              Data
              <span className="absolute bottom-0 left-0 w-full h-3 bg-primary opacity-50"></span>
            </span>{" "}
            Into
          </span>
          <span className="block">
            Actionable{" "}
            <span className="relative inline-block">
              Market
              <span className="absolute bottom-0 left-0 w-full h-3 bg-primary opacity-50"></span>
            </span>{" "}
            Insights
          </span>
        </h1>
        <h2 className="mt-4 text-xl md:text-2xl text-gray-500 max-w-3xl">
          Unlock real-time blockchain insights with Wevr.ai. Our AI-powered
          tools decode market trends, sentiment, and on-chain activity,
          empowering investors and researchers to stay ahead in the crypto
          space.
        </h2>
        <div className="flex items-center gap-x-6 mt-10">
          <AnimatedGetStartedButton onClick={handleGetStarted} />

          <motion.button
            onClick={handleLearnMore}
            className="text-gray-500 text-md sm:text-lg py-3 px-6 text-center font-semibold flex items-center gap-2 hover:text-secondary-dark group"
            whileHover="hover"
          >
            Learn more
            <motion.span
              variants={{
                hover: { x: 5 },
              }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <ArrowSmallRightIcon className="h-5 w-5" />
            </motion.span>
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
};
HeroSection.displayName = "HeroSection";

export default React.memo(HeroSection);
