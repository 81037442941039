import React, { useState, useEffect, useMemo, memo } from "react";
import { BentoCard, BentoGrid } from "../Ui/BentoGrid";
import { CommandLineIcon, CircleStackIcon } from "@heroicons/react/24/outline";
import { FileTextIcon, Share2Icon } from "lucide-react";
import AnimatedBeamDemo from "../Homepage/BentoObjects/AnimatedBeamDemo";
import HoverImage from "./hoverImage.js";
import TypingAnimation from "../Ui/TypingAnimation";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const generateSentimentData = () =>
  Array.from({ length: 30 }, () => Math.random() * 100);

const SentimentChart = memo(() => {
  const [chartData, setChartData] = useState(generateSentimentData);

  useEffect(() => {
    const interval = setInterval(
      () => setChartData(generateSentimentData),
      10000
    );
    return () => clearInterval(interval);
  }, []);

  const options = useMemo(
    () => ({
      chart: {
        type: "column",
        backgroundColor: "transparent",
        height: "80%",
        marginTop: 10,
        marginBottom: 100,
        animation: {
          duration: 1000,
          easing: "easeOutBounce",
        },
      },
      title: { text: null },
      xAxis: { visible: false },
      yAxis: {
        title: { text: null },
        labels: { style: { color: "#888" } },
        gridLineColor: "rgba(255,255,255,0.1)",
        min: 0,
        max: 100,
        tickPositions: [50, 75, 100],
        startOnTick: false,
        endOnTick: false,
      },
      series: [
        {
          name: "Market Sentiment",
          data: chartData,
          color: "#98fa73",
          marker: { enabled: false },
        },
      ],
      credits: { enabled: false },
      legend: { enabled: false },
      tooltip: {
        formatter: function () {
          return `Sentiment: ${Highcharts.numberFormat(this.y, 2)}`;
        },
      },
      plotOptions: {
        series: {
          lineWidth: 2,
          animation: {
            duration: 1000,
            easing: "easeOutBounce",
          },
        },
      },
    }),
    [chartData]
  );

  return <HighchartsReact highcharts={Highcharts} options={options} />;
});
SentimentChart.displayName = "SentimentChart";

const SQLCodeBox = memo(() => {
  const queries = [
    `SELECT blockchain, COUNT(*) as tx_count
FROM transactions
WHERE block_timestamp >= NOW() - INTERVAL 1 DAY
GROUP BY blockchain
ORDER BY tx_count DESC
LIMIT 5;`,
    `SELECT token_address, SUM(amount) as total_volume
FROM token_transfers
WHERE block_timestamp >= NOW() - INTERVAL 7 DAY
GROUP BY token_address
ORDER BY total_volume DESC
LIMIT 10;`,
    `SELECT from_address, COUNT(*) as interaction_count
FROM contract_interactions
WHERE contract_address = '0x1234...5678'
GROUP BY from_address
ORDER BY interaction_count DESC
LIMIT 15;`,
  ];

  return (
    <div className="w-full h-full flex flex-col dark:bg-gray-800">
      <div className="flex-grow p-3 overflow-auto">
        <pre className="text-xs dark:bg-gray-900 p-2 rounded-md overflow-x-auto whitespace-pre-wrap break-words h-full">
          <code className="text-secondary-dark dark:text-green-400 font-bold">
            <TypingAnimation
              text={queries}
              className="text-xs"
              typingSpeed={50}
              deletingSpeed={30}
              pauseDuration={200}
            />
          </code>
        </pre>
      </div>
    </div>
  );
});
SQLCodeBox.displayName = "SQLCodeBox";
const features = [
  {
    Icon: FileTextIcon,
    name: "Market Sentiment",
    description: "Track market sentiment trends",
    href: "/Explore?blockchain=Ethereum",
    cta: "Explore market sentiment analysis",
    className: "col-span-3 lg:col-span-1",
    background: (
      <div className="absolute inset-0 flex flex-col p-4">
        <div className="flex-grow mb-8">
          <SentimentChart />
        </div>
      </div>
    ),
  },
  {
    Icon: Share2Icon,
    name: "Blockchains",
    description: "Support for 6 Blockchains and 100+ dApps",
    href: "/Discover",
    cta: "Discover supported blockchains and dApps",
    className: "col-span-3 lg:col-span-2",
    background: (
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="w-full h-full max-w-[300px] lg:max-w-[500px] scale-75 lg:scale-100">
          <AnimatedBeamDemo />
        </div>
      </div>
    ),
  },
  {
    Icon: CircleStackIcon,
    name: "Databases",
    description: "Access to more than 50TB of data",
    href: "/Explore/Tables?blockchain=Ethereum",
    cta: "Explore Ethereum blockchain tables",
    className: "col-span-3 lg:col-span-2",
    background: (
      <div className="absolute right-2 top-4 h-[300px] w-[600px] border-none transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_10%,#000_100%)] group-hover:scale-105">
        <HoverImage link="/Explore?blockchain=Ethereum" />
      </div>
    ),
  },
  {
    Icon: CommandLineIcon,
    name: "SQL Query",
    description: "Write powerful SQL queries to analyze blockchain data",
    className: "col-span-3 lg:col-span-1",
    href: "/query",
    cta: "Try SQL queries for blockchain analysis",
    background: (
      <div className="absolute inset-0 flex items-center justify-center p-3">
        <SQLCodeBox />
      </div>
    ),
  },
];

export const BentoDemo = memo(() => {
  return (
    <div className="max-w-screen-xl mx-auto p-4">
      <BentoGrid>
        {features.map((feature, idx) => (
          <BentoCard key={idx} {...feature} />
        ))}
      </BentoGrid>
    </div>
  );
});
BentoDemo.displayName = "BentoDemo";

export default BentoDemo;
