import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ChartBar from "../Ui/CustomLogoAnimation";

function HoverImage({ link }) {
  const cardsRef = useRef(null);
  const [cursor, setCursor] = useState({ x: 0, y: 0 });
  const [mouseOnCard, setMouseOnCard] = useState(false);
  const navigate = useNavigate();

  const handleMouseMove = (event) => {
    if (cardsRef.current != null) {
      const rect = cardsRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      setCursor({ x: x, y: y });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      navigate(link);
    }
  };

  return (
    <section
      ref={cardsRef}
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setMouseOnCard(true)}
      onMouseLeave={() => setMouseOnCard(false)}
      onClick={() => navigate(link)}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      className="relative w-full h-[26rem] rounded-lg flex flex-row p-8 justify-between stroke-[0.1] hover:stroke-[0.2] cursor-pointer"
    >
      <div className="flex flex-col w-2/5 justify-between"></div>
      <div className="w-3/5 flex flex-col place-items-center p-4">
        <ChartBar
          cursor={cursor}
          cardRef={cardsRef}
          mouseOnCard={mouseOnCard}
        />
      </div>
    </section>
  );
}

export default HoverImage;
