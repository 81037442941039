import React, { memo, useMemo } from "react";
import { motion } from "framer-motion";

const TestimonialCard = memo(({ img, name, username, body }) => (
  <div className="shrink-0 w-80 rounded-xl overflow-hidden relative bg-gray-50 dark:bg-slate-900 border border-gray-200 dark:border-gray-800 p-4">
    <div className="flex items-center mb-2">
      <img
        src={img}
        alt={`${name}'s profile`}
        className="w-12 h-12 rounded-full mr-3"
        loading="lazy"
      />
      <div>
        <h4 className="font-semibold text-slate-900 dark:text-slate-100">
          {name}
        </h4>
        <a
          href={`https://twitter.com/${username}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm text-primary hover:underline"
        >
          @{username}
        </a>
      </div>
    </div>
    <p className="text-slate-600 dark:text-slate-300 text-sm">{body}</p>
    <span className="text-6xl absolute top-2 right-2 text-slate-200 dark:text-slate-700"></span>
  </div>
));
TestimonialCard.displayName = "TestimonialCard";

const TestimonialList = memo(({ list, reverse = false, duration = 50 }) => {
  const animationProps = useMemo(
    () => ({
      initial: { translateX: reverse ? "-100%" : "0%" },
      animate: { translateX: reverse ? "0%" : "-100%" },
      transition: { duration, repeat: Infinity, ease: "linear" },
    }),
    [reverse, duration]
  );

  return (
    <motion.div {...animationProps} className="flex gap-4 px-2">
      {list.map((t) => (
        <TestimonialCard key={t.username} {...t} />
      ))}
    </motion.div>
  );
});
TestimonialList.displayName = "TestimonialList";

const GradientOverlay = memo(({ direction }) => (
  <div
    className={`absolute top-0 bottom-0 ${direction} w-24 z-10 bg-gradient-to-r from-white to-transparent dark:from-slate-950 ${
      direction === "right-0" ? "rotate-180" : ""
    }`}
  />
));
GradientOverlay.displayName = "GradientOverlay";

const TwitterStyleTestimonials = () => {
  const memoizedTestimonials = useMemo(
    () => ({
      top: testimonials.top,
      bottom: testimonials.bottom,
    }),
    []
  );

  return (
    <div className="bg-white dark:bg-slate-950 py-12">
      <div className="mb-8 px-4">
        <h3 className="text-slate-900 dark:text-slate-50 text-4xl font-semibold text-center">
          What Our Users Say
        </h3>
        <p className="text-center text-slate-600 dark:text-slate-300 text-sm mt-2 max-w-lg mx-auto">
          See what our users are saying about Wevr's real-time blockchain
          analytics
        </p>
      </div>
      <div className="p-4 overflow-x-hidden relative">
        <GradientOverlay direction="left-0" />

        <div className="flex items-center mb-4">
          <TestimonialList list={memoizedTestimonials.top} duration={125} />
          <TestimonialList list={memoizedTestimonials.top} duration={125} />
        </div>
        <div className="flex items-center">
          <TestimonialList
            list={memoizedTestimonials.bottom}
            duration={175}
            reverse
          />
          <TestimonialList
            list={memoizedTestimonials.bottom}
            duration={175}
            reverse
          />
        </div>

        <GradientOverlay direction="right-0" />
      </div>
    </div>
  );
};

const testimonials = {
  top: [
    {
      name: "Kuriboh",
      username: "Cryptokuriboh",
      body: "Wevr's on-chain analytics have revolutionized my trading strategies. The real-time blockchain insights are incredible!",
      img: "https://pbs.twimg.com/profile_images/987239307382480896/NxN_Twcl_400x400.jpg",
    },
    {
      name: "chips_chubby",
      username: "chips_chubby",
      body: "The depth of on-chain data Wevr provides is unparalleled. It's a game-changer for crypto market analysis.",
      img: "https://pbs.twimg.com/profile_images/1567896867190751235/-gg1H1vo_400x400.jpg",
    },
    {
      name: "1960_c",
      username: "1960_c",
      body: "Wevr's blockchain footprint tracker and analytics tools have given me unprecedented visibility into network activities.",
      img: "https://pbs.twimg.com/profile_images/1758066059071168512/pI25vjQ-_400x400.jpg",
    },
    {
      name: "Meeshcrypto",
      username: "BigMeeshcrypto",
      body: "The on-chain whale tracking feature from Wevr is a goldmine for predicting market movements. Absolutely essential!",
      img: "https://pbs.twimg.com/profile_images/1786937382799089664/5EDHgtxC_400x400.jpg",
    },
    {
      name: "Brian",
      username: "Brian_crypt0",
      body: "Wevr's Query integration for blockchain data is seamless. It's made our DeFi analytics so much more comprehensive.",
      img: "https://pbs.twimg.com/profile_images/1462608806068690947/0IyTuJvp_400x400.jpg",
    },
  ],
  bottom: [
    {
      name: "Poor",
      username: "poorxbt",
      body: "The customizable on-chain analytics dashboards from Wevr have streamlined our entire crypto research workflow.",
      img: "https://pbs.twimg.com/profile_images/1460406250408005633/XLkh-YNu_400x400.jpg",
    },
    {
      name: "Joker",
      username: "cryptojokerrr",
      body: "Wevr's predictive on-chain analytics have given us a significant edge in tracking whale movements. Truly impressive!",
      img: "https://pbs.twimg.com/profile_images/1729735072063098880/XqJ4vyn__400x400.jpg",
    },
    {
      name: "kathryncryptox",
      username: "kathryncryptox",
      body: "The Near real-time blockchain data charts from Wevr are a game-changer for our algorithmic trading strategies.",
      img: "https://pbs.twimg.com/profile_images/1481111122405359619/5u0ZUS3S_400x400.jpg",
    },
    {
      name: "nofffman",
      username: "nofffman",
      body: "For blockchain auditing and on-chain security analysis, Wevr's tools are awesome. They've elevated our assessments.",
      img: "https://pbs.twimg.com/profile_images/1752198052428013568/Dxsf6DX3_400x400.jpg",
    },
    {
      name: "CoolingCrypto",
      username: "CoolingCrypto",
      body: "Wevr's on-chain analytics have been crucial in our token analysis and community engagement tracking.",
      img: "https://pbs.twimg.com/profile_images/1440297536015638540/bQi9PXBg_400x400.jpg",
    },
    {
      name: "Julie_crypto",
      username: "Julie_crypto",
      body: "The cross-chain data integration in Wevr's platform has revolutionized our multi-blockchain portfolio management.",
      img: "https://pbs.twimg.com/profile_images/1484303754573025282/YJ6qt2Pn_400x400.jpg",
    },
  ],
};
export default TwitterStyleTestimonials;
