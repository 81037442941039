import React, { Fragment } from "react";
import { CheckIcon, MinusIcon } from "@heroicons/react/20/solid";
import Background from "./background";
const tiers = [
  {
    name: "CryptoQuant",
    id: "tier-cryptoquant",
    mostPopular: false,
  },
  {
    name: "Wevr.ai",
    id: "tier-wevr",
    href: "#",
    mostPopular: true,
  },
  {
    name: "Glassnode",
    id: "tier-glassnode",
    mostPopular: false,
  },
];

const mobileTiers = [tiers[1], tiers[0], tiers[2]];

const sections = [
  {
    name: "Analytics Features",
    features: [
      {
        name: "Real-time transaction monitoring",
        tiers: { CryptoQuant: true, "Wevr.ai": true, Glassnode: true },
      },
      {
        name: "Address labeling",
        tiers: { CryptoQuant: true, "Wevr.ai": true, Glassnode: true },
      },
      {
        name: "Smart contract analysis",
        tiers: { CryptoQuant: false, "Wevr.ai": true, Glassnode: true },
      },
      {
        name: "Custom analytics dashboards",
        tiers: {
          CryptoQuant: "Limited",
          "Wevr.ai": "Unlimited",
          Glassnode: "Limited",
        },
      },
      {
        name: "Query System",
        tiers: {
          CryptoQuant: "Limited",
          "Wevr.ai": "True",
          Glassnode: "False",
        },
      },
    ],
  },
  {
    name: "Data Access",
    features: [
      {
        name: "Historical data access",
        tiers: {
          CryptoQuant: "Pay walled",
          "Wevr.ai": "Full access",
          Glassnode: "Pay walled",
        },
      },
      {
        name: "Data granularity",
        tiers: {
          CryptoQuant: "1 min",
          "Wevr.ai": "Block time",
          Glassnode: "10 min",
        },
      },
      {
        name: "API access",
        tiers: { CryptoQuant: true, "Wevr.ai": true, Glassnode: true },
      },
      {
        name: "Bulk data export",
        tiers: { CryptoQuant: false, "Wevr.ai": true, Glassnode: false },
      },
      {
        name: "Real-time alerts",
        tiers: { CryptoQuant: true, "Wevr.ai": true, Glassnode: true },
      },
    ],
  },
  {
    name: "Cost and Licensing",
    features: [
      {
        name: "Pricing Options",
        tiers: {
          CryptoQuant: "Basic, Pro",
          "Wevr.ai": "Basic, Pro, Enterprise",
          Glassnode: "Basic, Pro",
        },
      },
      {
        name: "Free Trial",
        tiers: { CryptoQuant: false, "Wevr.ai": true, Glassnode: false },
      },
      {
        name: "Enterprise Licensing",
        tiers: { CryptoQuant: true, "Wevr.ai": true, Glassnode: true },
      },
      {
        name: "Lowest Price",
        tiers: {
          CryptoQuant: "$29/month",
          "Wevr.ai": "$25/month",
          Glassnode: "$29/month",
        },
      },
      {
        name: "Free Tier",
        tiers: { CryptoQuant: true, "Wevr.ai": true, Glassnode: true },
      },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const BackgroundGrid = () => {
  return (
    <div className="absolute inset-0 z-0 overflow-hidden">
      <div
        style={{
          backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgb(15 23 42 / 0.04)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e")`,
        }}
        className="absolute inset-0"
      >
        <div
          style={{
            backgroundImage:
              "radial-gradient(at 100% 50%, rgba(249,250,251,0) 0, rgba(249,250,251,1) 50%, rgba(249,250,251,1) 100%)",
          }}
          className="absolute inset-0"
        />
      </div>
    </div>
  );
};
export default function BlockchainAnalyticsComparison() {
  return (
    <div className=" py-24 sm:py-32 relative bg-gray-50">
      <BackgroundGrid />
      <div className="mx-auto max-w-7xl px-6 lg:px-8 relative  z-10">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-xl font-semibold leading-8 text-primary">
            Comparison
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl  font-monda">
            Blockchain Analytics Solutions
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-xl leading-8 text-gray-600">
          Compare our advanced blockchain analytics platform with competitors to
          see why Wevr is the best choice for your project.
        </p>

        {/* xs to lg */}
        <div className="mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden z-10">
          {mobileTiers.map((tier) => (
            <section
              key={tier.id}
              className={classNames(
                tier.mostPopular
                  ? "rounded-xl bg-white shadow-md ring-1 ring-primary"
                  : "rounded-xl bg-gray-50 shadow",
                "p-8"
              )}
            >
              <h3
                id={tier.id}
                className={classNames(
                  "text-4xl leading-10 text-gray-900 text-center mb-8",
                  tier.mostPopular ? "font-bold" : "font-normal"
                )}
              >
                {tier.name}
              </h3>
              {tier.mostPopular && (
                <a
                  href={tier.href}
                  className="block rounded-md bg-primary px-3 py-2 text-center text-lg font-semibold leading-6 text-white hover:bg-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                >
                  Learn more
                </a>
              )}
              <ul
                role="list"
                className="mt-8 space-y-3 text-base leading-7 text-gray-600"
              >
                {sections.map((section) => (
                  <li key={section.name}>
                    <ul role="list" className="space-y-3">
                      {section.features.map((feature) => (
                        <li key={feature.name} className="flex gap-x-3">
                          {feature.tiers[tier.name] ? (
                            <>
                              <CheckIcon
                                className="h-7 w-6 flex-none text-secondary-dark font-extrabold"
                                aria-hidden="true"
                              />
                              <span>
                                {feature.name}{" "}
                                {typeof feature.tiers[tier.name] ===
                                "string" ? (
                                  <span className="text-gray-500">
                                    ({feature.tiers[tier.name]})
                                  </span>
                                ) : null}
                              </span>
                            </>
                          ) : (
                            <>
                              <MinusIcon
                                className="h-7 w-6 flex-none text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="text-gray-400">
                                {feature.name}
                              </span>
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </section>
          ))}
        </div>

        {/* lg+ */}
        <div className="isolate mt-20 hidden lg:block z-10">
          <div className="relative -mx-8">
            {tiers.some((tier) => tier.mostPopular) ? (
              <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                <div
                  className="flex w-1/4 px-4"
                  aria-hidden="true"
                  style={{
                    marginLeft: `${
                      (tiers.findIndex((tier) => tier.mostPopular) + 1) * 25
                    }%`,
                  }}
                >
                  <div className="w-full rounded-t-xl border-x border-t border-primary/10 bg-primary/5" />
                </div>
              </div>
            ) : null}
            <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
              <caption className="sr-only">
                Blockchain analytics comparison
              </caption>
              <colgroup>
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
              </colgroup>
              <thead>
                <tr>
                  <td />
                  {tiers.map((tier) => (
                    <th
                      key={tier.id}
                      scope="col"
                      className={classNames(
                        tier.mostPopular
                          ? "bg-primary/5 rounded-tl-xl rounded-tr-xl"
                          : "",
                        "relative w-1/4 px-6 pt-6 xl:px-8 xl:pt-8"
                      )}
                    >
                      <div className="min-h-[120px] flex flex-col items-center justify-between">
                        <div
                          className={classNames(
                            "text-4xl leading-10 text-gray-900 mb-4",
                            tier.mostPopular ? "font-bold" : "font-normal"
                          )}
                        >
                          {tier.name}
                        </div>
                        {tier.mostPopular && (
                          <a
                            href={tier.href}
                            className="w-full rounded-md bg-primary px-3 py-2 text-center text-lg font-semibold leading-6 text-white hover:bg-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                          >
                            Learn more
                          </a>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sections.map((section, sectionIdx) => (
                  <Fragment key={section.name}>
                    <tr>
                      <th
                        scope="colgroup"
                        colSpan={4}
                        className={classNames(
                          sectionIdx === 0 ? "pt-8" : "pt-16",
                          "pb-4 text-lg font-semibold leading-6 text-gray-900"
                        )}
                      >
                        {section.name}
                        <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                      </th>
                    </tr>
                    {section.features.map((feature) => (
                      <tr key={feature.name}>
                        <th
                          scope="row"
                          className="py-4 text-base font-normal leading-7 text-gray-900"
                        >
                          {feature.name}
                          <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                        </th>
                        {tiers.map((tier) => (
                          <td key={tier.id} className="px-6 py-4 xl:px-8">
                            {typeof feature.tiers[tier.name] === "string" ? (
                              <div className="text-center text-base leading-7 text-gray-500">
                                {feature.tiers[tier.name]}
                              </div>
                            ) : (
                              <>
                                {feature.tiers[tier.name] === true ? (
                                  <CheckIcon
                                    className="mx-auto h-8 w-8 text-secondary-dark font-extrabold"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <MinusIcon
                                    className="mx-auto h-6 w-6 text-gray-400"
                                    aria-hidden="true"
                                  />
                                )}
                                <span className="sr-only">
                                  {feature.tiers[tier.name] === true
                                    ? "Included"
                                    : "Not included"}{" "}
                                  in {tier.name}
                                </span>
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
