// Created by James Launder inspired by tailwind ui
// standard footer

import { Link } from "react-router-dom";
const navigation = {
  main: [
    { name: "Explore", href: "/Explore?blockchain=Ethereum" },
    { name: "Discover", href: "/Discover" },
    { name: "Footprint", href: "/Footprint" },
    { name: "Dashboard", href: "/Dashboard" },
    { name: "Pricing", href: "/Pricing" },
    { name: "FAQ", href: "/FAQ" },
    { name: "Legal", href: "/Legal" },
    { name: "Contact Us", href: "/Contact" },
    { name: "Glossary", href: "/Glossary" }, 

  ],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/WevrPtyLtd/",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Twitter",
      href: "https://twitter.com/wevr_ai",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
          />
        </svg>
      ),
    },
    {
      name: "Medium",
      href: "https://medium.com/@wevr.ai",
      icon: (props) => (
        <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
          <path d="M13.54 12a6.8 6.8 0 01-6.77 6.82A6.8 6.8 0 010 12a6.8 6.8 0 016.77-6.82A6.8 6.8 0 0113.54 12zM20.96 12c0 3.54-1.51 6.42-3.38 6.42-1.87 0-3.39-2.88-3.39-6.42s1.52-6.42 3.39-6.42 3.38 2.88 3.38 6.42M24 12c0 3.17-.53 5.75-1.19 5.75-.66 0-1.19-2.58-1.19-5.75s.53-5.75 1.19-5.75C23.47 6.25 24 8.83 24 12z"></path>
        </svg>
      ),
    },
    {
      name: "TikTok",
      href: "https://www.tiktok.com/@wevr_ai",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z"></path>
        </svg>
      ),
    },
    {
      name: "Youtube",
      href: "https://www.youtube.com/@wevrai",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"></path>
        </svg>
      ),
    },
  ],
};

export default function Footer() {
  return (
    <footer className="border-t border">
      <div className="mx-auto max-w-7xl overflow-hidden py-10 px-4 sm:px-6 lg:px-8">
        <nav
          className="-mx-5 pt-4 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {navigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <Link
                to={item.href}
                className="text-base text-gray-500 hover:text-gray-900"
              >
                {item.name}
              </Link>
            </div>
          ))}
          <div className="px-5 py-2">
            <a
              href="https://docs.wevr.ai"
              target="_blank"
              rel="noopener noreferrer"
              className="text-base text-gray-500 hover:text-gray-900"
            >
              Documentation
            </a>
          </div>
          <div className="px-5 py-2">
            <a
              href="https://blog.wevr.ai"
              target="_blank"
              rel="noopener noreferrer"
              className="text-base text-gray-500 hover:text-gray-900"
            >
              Blog
            </a>
          </div>
        </nav>
        <div className="mt-8 flex justify-center space-x-6">
          {navigation.social.map((item) => (
            <a
              key={item.name}
              href={item.href}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-gray-500 items-center flex"
            >
              <span className="sr-only">{item.name}</span>
              {item.icon ? (
                <item.icon className="h-6 w-6" aria-hidden="true" />
              ) : (
                <span>{item.name}</span>
              )}
            </a>
          ))}
        </div>
        <p className="mt-8 text-center text-base text-gray-400">
          &copy; {new Date().getFullYear()} Wevr Pty Ltd All rights reserved.
          ACN: 665 121 540
        </p>
      </div>
    </footer>
  );
}
