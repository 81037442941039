import React from "react";
import { Link } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";

const BackgroundGrid = () => {
  return (
    <div
      style={{
        backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgb(255 255 255 / 0.1)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e")`,
      }}
      className="absolute inset-0 z-0"
    />
  );
};

const RoundedSlideButton = () => {
  return (
    <button
      className={`
        relative z-0 flex items-center gap-2 overflow-hidden rounded-lg border-[1px]
        border-white px-4 py-2 font-semibold
        uppercase text-white transition-all duration-500

        before:absolute before:inset-0
        before:-z-10 before:translate-x-[150%]
        before:translate-y-[150%] before:scale-[2.5]
        before:rounded-[100%] before:bg-white
        before:transition-transform before:duration-1000
        before:content-[""]

        hover:scale-105 hover:text-primary
        hover:before:translate-x-[0%]
        hover:before:translate-y-[0%]
        active:scale-95`}
    >
      <FiLogIn />
      <span>Sign up free</span>
    </button>
  );
};

const GradientShadowButton = ({ to, children }) => {
  return (
    <Link
      to={to}
      className="group relative w-fit transition-transform duration-300 active:scale-95"
    >
      <button className="relative z-10 rounded-lg bg-white p-0.5 duration-300 group-hover:scale-105 shadow-md group-hover:shadow-lg">
        <span className="block rounded-md px-4 py-2 font-semibold text-primary duration-300 group-hover:bg-primary group-hover:text-white">
          {children}
        </span>
      </button>
      <span className="pointer-events-none absolute -inset-4 z-0 transform-gpu rounded-2xl bg-gradient-to-br from-primary to-primary-light opacity-0 blur-xl transition-all duration-300 group-hover:opacity-30" />
    </Link>
  );
};

const CTASection = ({ headerText, bodyText }) => {
  return (
    <div className="px-4 py-16 sm:px-6 lg:px-8 relative">
      <div className="relative isolate overflow-hidden bg-primary/90 py-16 sm:py-24 lg:py-32 rounded-3xl">
        <BackgroundGrid />
        <div className="mx-auto max-w-7xl px-6 lg:px-8 relative z-10">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
            <div className="max-w-xl lg:max-w-lg">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                {headerText || "Ready to enhance your blockchain insights?"}
              </h2>
              <p className="mt-4 text-lg font-medium leading-8 text-gray-100">
                {bodyText ||
                  "Unlock powerful analytics and real-time data for smarter decision-making."}
              </p>
              <div className="mt-6 flex max-w-md gap-x-4">
                <GradientShadowButton to="/Explore?blockchain=Ethereum">
                  Get started
                </GradientShadowButton>
                <Link to="/SignUp">
                  <RoundedSlideButton />
                </Link>
              </div>
            </div>
            <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
              <div className="flex flex-col items-start">
                <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                  <svg
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                    />
                  </svg>
                </div>
                <dt className="mt-4 font-bold text-white text-lg">
                  Real-time updates
                </dt>
                <dd className="mt-2 font-medium leading-7 text-gray-100">
                  Stay informed with live blockchain data and analytics.
                </dd>
              </div>
              <div className="flex flex-col items-start">
                <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                  <svg
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
                    />
                  </svg>
                </div>
                <dt className="mt-4 font-bold text-white text-lg">
                  Advanced analytics
                </dt>
                <dd className="mt-2 font-medium leading-7 text-gray-100">
                  Gain deeper insights with our powerful analytical tools.
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div
          className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6"
          aria-hidden="true"
        >
          <div
            className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#98fa73] to-[#9089fc] opacity-30"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CTASection;
