import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import AnimatedBadge from "../Homepage/AnimatedBadge";
import Background from "../Homepage/background";

const Glossary = () => {
    const cryptoTerms = [
        { term: "Blockchain", definition: "A decentralized, distributed ledger technology that records transactions across many computers." },
        { term: "Bitcoin", definition: "The first and most well-known cryptocurrency, created by an anonymous person or group under the pseudonym Satoshi Nakamoto." },
        { term: "Ethereum", definition: "A decentralized, open-source blockchain featuring smart contract functionality." },
        { term: "Smart Contract", definition: "Self-executing contracts with the terms of the agreement directly written into code." },
        { term: "DeFi", definition: "Decentralized Finance; a system of financial services built on blockchain technologies." },
        { term: "NFT", definition: "Non-Fungible Token; a unique digital asset verified using blockchain technology." },
        { term: "Mining", definition: "The process of validating and adding new transactions to a blockchain." },
        { term: "Wallet", definition: "A digital tool that allows users to store and manage their cryptocurrencies." },
        { term: "Exchange", definition: "A platform where users can buy, sell, or trade cryptocurrencies." },
        { term: "HODL", definition: "A term derived from a misspelling of 'hold,' used in the crypto community to refer to buying and holding cryptocurrencies long-term." },
        { term: "On-Chain Data", definition: "Information that is stored directly on a blockchain network and can be verified by anyone with access to that network." },
        { term: "Blockchain Analytics", definition: "The analysis of on-chain data to derive insights about network activity, user behavior, and market trends." },
        { term: "Crypto Analytics", definition: "The analysis of both on-chain and off-chain data related to cryptocurrencies to inform investment decisions and understand market dynamics." },
        { term: "EMA", definition: "Exponential Moving Average; a type of moving average that gives more weight to recent price data, used in crypto trading for identifying trends." },
        { term: "UTXO", definition: "Unspent Transaction Output; a model used by some cryptocurrencies to track and validate transactions." },
        { term: "Hash Rate", definition: "The speed at which a computer or network is completing hash operations in cryptocurrency mining." },
        { term: "Consensus Mechanism", definition: "A protocol that ensures all nodes in a blockchain network agree on the validity of transactions and their order." },
        { term: "Gas Fee", definition: "The cost required to perform a transaction or execute a contract on the Ethereum blockchain." },
        { term: "Liquidity Pool", definition: "A collection of funds locked in a smart contract, used to facilitate decentralized trading and other DeFi activities." },
        { term: "Yield Farming", definition: "A way to generate rewards with cryptocurrency holdings, usually by lending or staking crypto assets." },
        { term: "Token Burn", definition: "The process of permanently removing a certain amount of cryptocurrency tokens from circulation." },
        { term: "Stablecoin", definition: "A type of cryptocurrency designed to maintain a stable value relative to a reference asset, often a fiat currency." },
        { term: "Cold Storage", definition: "Keeping a reserve of cryptocurrency offline to protect it from hacking, theft, or accidental loss." },
        { term: "DEX", definition: "Decentralized Exchange; a type of cryptocurrency exchange that operates without a central authority." },
        { term: "Tokenomics", definition: "The economic model and design principles of a cryptocurrency or token." },
        { term: "Layer 2", definition: "A secondary framework or protocol built on top of an existing blockchain to solve transaction speed and scaling difficulties." },
        { term: "DAO", definition: "Decentralized Autonomous Organization; an organization represented by rules encoded as a computer program that is transparent and controlled by the organization members." },
        { term: "Nonce", definition: "A number used once in cryptographic communication, often used in proof-of-work mining and to ensure transaction uniqueness." },
        { term: "Mempool", definition: "A cryptocurrency node's mechanism for storing information on unconfirmed transactions." },
        { term: "Atomic Swap", definition: "A smart contract technology that enables the exchange of one cryptocurrency for another without using centralized intermediaries." },
        { term: "Hard Fork", definition: "A radical change to a network's protocol that makes previously invalid blocks and transactions valid, or vice-versa." },
        { term: "Multisig", definition: "A type of cryptocurrency wallet that requires two or more private keys to sign and send a transaction." },
        { term: "Zero-Knowledge Proof", definition: "A cryptographic method by which one party can prove to another party that they know a value X, without conveying any information apart from the fact that they know the value X." },
        { term: "AMM", definition: "Automated Market Maker; a decentralized asset trading model that uses a mathematical formula to price assets." },
        { term: "Blockchain Bridge", definition: "A connection that allows the transfer of tokens and/or arbitrary data from one blockchain network to another." },
        { term: "Oracle", definition: "A third-party service that provides smart contracts with external information." },
        { term: "Gas Optimization", definition: "The practice of minimizing the computational resources required for executing transactions or smart contracts on a blockchain." },
        { term: "Slippage", definition: "The difference between the expected price of a trade and the price at which the trade is actually executed." }
      ];

  return (
    <div className="relative pt-10 pb-20 sm:py-24 z-0 overflow-hidden bg-white">
      <Background />
      <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0.0, y: 40 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{
            delay: 0.2,
            duration: 0.7,
            ease: "easeInOut",
          }}
          className="text-center"
        >
          <div className="inline-block">
            <AnimatedBadge href="#" newLabel="New">
              Crypto Knowledge Base
            </AnimatedBadge>
          </div>

          <h1 className="mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            Crypto Glossary
          </h1>
          <p className="mx-auto mt-3 max-w-md text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-3xl md:text-xl">
            Every important crypto term explained in detail.
          </p>
        </motion.div>

        <div className="mt-16 grid gap-8 md:grid-cols-2">
          {cryptoTerms.map((item, index) => (
            <motion.div
              key={item.term}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="relative rounded-lg border border-gray-200 bg-white px-6 py-5 shadow-sm hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary"
            >
              <Link to={`/Glossary/${item.term.toLowerCase().replace(/\s+/g, '-')}`} className="block">
                <div className="flex-1">
                  <h3 className="text-lg font-medium text-gray-900 mb-2">{item.term}</h3>
                  <p className="text-sm text-gray-500">{item.definition}</p>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Glossary;