import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PrimaryFeatures2 from "./primaryFeatures.js";
import HeroSection from "./heroSection.js";
import BlockchainsContainer from "./blockchainsContainer.js";
import BentoDemo from "./BentoGrid.js";
import TwitterStyleTestimonials from "./twitterMarquee.js";
import StatsSection from "./statsSection";
import BlogSection from "./blogSection";
import ZigzagFeaturesSection from "./zigzagFeature.js";
import ComparisonSection from "./comparisonSection.js";
import CTASection from "./ctaSection.js";

import ThemeToggle from "../Darkmode/Toggle"; // Import the ThemeToggle component
function Homepage() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.pathname) {
      navigate("/", { replace: true });
      window.location.reload();
    }
  }, [location.state?.pathname]);

  return (
    <div className="">
      <HeroSection />
      <div className="">
        <PrimaryFeatures2 />
      </div>
      <ZigzagFeaturesSection />
      <StatsSection />
      <ComparisonSection />
      <BlockchainsContainer />
      <BentoDemo />
      <TwitterStyleTestimonials />
      <BlogSection />
      <CTASection />
      {/* Add the ThemeToggle at the end */}
    </div>
  );
}

export default Homepage;
