import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import { FiArrowUpRight } from "react-icons/fi";
import BaseLogo from "../../Assets/base.svg";
import BitcoinLogo from "../../Assets/bitcoin-1.svg";
import LineaLogo from "../../Assets/linea.svg";
import EthereumLogo from "../../Assets/ethereum1.svg";
import GnosisLogo from "../../Assets/gnosis.svg";
import OptimismLogo from "../../Assets/optimism.svg";

export const BlockchainLogos = () => {
  return (
    <section className="flex flex-col items-center justify-center gap-12 bg-white px-4 py-24 md:flex-row">
      <Copy />
      <LogoRolodex
        items={[
          <LogoItem key={1} src={EthereumLogo} alt="Ethereum" />,
          <LogoItem key={2} src={BitcoinLogo} alt="Bitcoin" />,
          <LogoItem key={3} src={OptimismLogo} alt="Optimism" />,
          <LogoItem key={4} src={BaseLogo} alt="Base" />,
          <LogoItem key={5} src={LineaLogo} alt="Linea" />,
          <LogoItem key={6} src={GnosisLogo} alt="Gnosis" />,
        ]}
      />
    </section>
  );
};

const Copy = () => (
  <div className="max-w-lg text-center md:text-start">
    <h2 className="mb-3 text-5xl text-black font-monda">
      Support for the most popular blockchains
    </h2>
    <p className="mb-6 text-base leading-relaxed text-neutral-600">
      Monitor Crypto Movements Instantly: See Fund Shifts, Identify New
      Projects, and Trace Transactions with 15-Second Node to Website Delay and
      Block-Level Precision.
    </p>
    <a
      href="#"
      className="group text-base uppercase text-primary transition-colors hover:text-primary-dark"
    >
      See all supported chains
      <FiArrowUpRight className="inline-block text-lg transition-transform group-hover:rotate-45" />
    </a>
  </div>
);

const DELAY_IN_MS = 2500;
const TRANSITION_DURATION_IN_SECS = 1.5;

const LogoRolodex = ({ items }) => {
  const intervalRef = useRef(null);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setIndex((pv) => pv + 1);
    }, DELAY_IN_MS);

    return () => {
      clearInterval(intervalRef.current || undefined);
    };
  }, []);

  return (
    <div
      style={{
        transform: "rotateY(-20deg)",
        transformStyle: "preserve-3d",
      }}
      className="relative z-0 h-52 w-72 shrink-0 rounded-xl border border-neutral-200 bg-secondary-new bg-opacity-30"
    >
      <AnimatePresence mode="sync">
        <motion.div
          style={{
            y: "-50%",
            x: "-50%",
            clipPath: "polygon(0 0, 100% 0, 100% 50%, 0 50%)",
            zIndex: -index,
            backfaceVisibility: "hidden",
          }}
          key={index}
          transition={{
            duration: TRANSITION_DURATION_IN_SECS,
            ease: "easeInOut",
          }}
          initial={{ rotateX: "0deg" }}
          animate={{ rotateX: "0deg" }}
          exit={{ rotateX: "-180deg" }}
          className="absolute left-1/2 top-1/2"
        >
          {items[index % items.length]}
        </motion.div>
        <motion.div
          style={{
            y: "-50%",
            x: "-50%",
            clipPath: "polygon(0 50%, 100% 50%, 100% 100%, 0 100%)",
            zIndex: index,
            backfaceVisibility: "hidden",
          }}
          key={(index + 1) * 2}
          initial={{ rotateX: "180deg" }}
          animate={{ rotateX: "0deg" }}
          exit={{ rotateX: "0deg" }}
          transition={{
            duration: TRANSITION_DURATION_IN_SECS,
            ease: "easeInOut",
          }}
          className="absolute left-1/2 top-1/2"
        >
          {items[index % items.length]}
        </motion.div>
      </AnimatePresence>

      <hr
        style={{
          transform: "translateZ(1px)",
        }}
        className="absolute left-0 right-0 top-1/2 z-[999999999] -translate-y-1/2 border-t-2 border-neutral-200"
      />
    </div>
  );
};

const LogoItem = ({ src, alt }) => {
  return (
    <div
      className={twMerge(
        "grid h-44 w-64 place-content-center rounded-lg bg-white"
      )}
    >
      <img src={src} alt={alt} className="max-h-16 w-auto object-contain" />
    </div>
  );
};

export default BlockchainLogos;
