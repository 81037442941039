import React, { useEffect, useState, useCallback } from "react";
import { cn } from "../../utils/utils2";

function TypingAnimation({
  text,
  className,
  typingSpeed = 50,
  deletingSpeed = 30,
  pauseDuration = 100,
}) {
  const [displayedText, setDisplayedText] = useState("");
  const [isTyping, setIsTyping] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  const texts = Array.isArray(text) ? text : [text];

  const moveToNextText = useCallback(() => {
    setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    setIsTyping(true);
    setIsDeleting(false);
    setIsPaused(false);
    setDisplayedText("");
  }, [texts.length]);

  // Handle typing and deleting
  useEffect(() => {
    let timer;

    if (isTyping && !isPaused) {
      if (displayedText.length < texts[currentTextIndex].length) {
        timer = setTimeout(() => {
          setDisplayedText(
            texts[currentTextIndex].slice(0, displayedText.length + 1)
          );
        }, typingSpeed);
      } else {
        setIsTyping(false);
        setIsPaused(true);
      }
    } else if (isDeleting && !isPaused) {
      if (displayedText.length > 0) {
        timer = setTimeout(() => {
          setDisplayedText(displayedText.slice(0, -1));
        }, deletingSpeed);
      } else {
        moveToNextText();
      }
    }

    return () => clearTimeout(timer);
  }, [
    displayedText,
    isTyping,
    isDeleting,
    isPaused,
    currentTextIndex,
    texts,
    typingSpeed,
    deletingSpeed,
    moveToNextText,
  ]);

  // Handle pause and transition to deleting
  useEffect(() => {
    let pauseTimer;
    if (isPaused) {
      pauseTimer = setTimeout(() => {
        setIsPaused(false);
        setIsDeleting(true);
      }, pauseDuration);
    }
    return () => clearTimeout(pauseTimer);
  }, [isPaused, pauseDuration]);

  return (
    <span
      className={cn("font-mono whitespace-pre-wrap break-words", className)}
    >
      {displayedText}
    </span>
  );
}

export default TypingAnimation;
