import React, { memo } from "react";
import Alpha from "../../Assets/query.png";
import BorderBeam from "../Ui/Borderbeam";

const BORDER_BEAM_CONFIG = {
  size: 450,
  duration: 12,
  colorFrom: "#FF6B35",
  colorTo: "#FF8C61",
  borderWidth: 2,
};

const ImagePlaceholder =
  "https://via.placeholder.com/1280x720?text=Image+Not+Found";

const OptimizedImage = memo(({ src, alt, className }) => {
  const [imageSrc, setImageSrc] = React.useState(src);

  const handleError = () => {
    setImageSrc(ImagePlaceholder);
  };

  return (
    <img
      src={imageSrc}
      alt={alt}
      className={className}
      onError={handleError}
      loading="lazy"
    />
  );
});
OptimizedImage.displayName = "OptimizedImage";

const BeamContainer = memo(({ children }) => (
  <div className="relative rounded-2xl bg-white/80 backdrop-blur-md shadow-xl min-h-[300px] sm:min-h-[400px] md:min-h-[600px] lg:min-h-[800px] max-w-[1280px] mx-auto">
    {children}
  </div>
));
BeamContainer.displayName = "BeamContainer";

const PrimaryFeatures = () => {
  return (
    <section className="relative overflow-hidden pb-10">
      <div className="container mx-auto px-4 relative z-10">
        <BeamContainer>
          <BorderBeam
            {...BORDER_BEAM_CONFIG}
            delay={0}
            anchor={0}
            style={{ zIndex: 2 }}
          />
          <BorderBeam
            {...BORDER_BEAM_CONFIG}
            delay={7}
            anchor={100}
            style={{ zIndex: 1 }}
          />
          <div className="relative z-10 w-full h-full rounded-xl overflow-hidden p-2">
            <OptimizedImage
              src={Alpha}
              alt="Alpha"
              className="w-full h-full object-cover"
            />
          </div>
        </BeamContainer>
      </div>
    </section>
  );
};

export default memo(PrimaryFeatures);
