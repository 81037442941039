import React, { useEffect, useRef } from "react";
import { animate, useInView } from "framer-motion";

const Stat = ({ num, suffix = "", decimals = 0, name }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });

  useEffect(() => {
    if (!isInView || !ref.current) return;

    const controls = animate(0, num, {
      duration: 2.5,
      onUpdate(value) {
        ref.current.textContent = value.toFixed(decimals);
      },
    });

    return () => controls.stop();
  }, [num, decimals, isInView]);

  return (
    <div className="text-center px-1 sm:px-2 md:px-4 flex flex-col items-center">
      <div className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold h-[1.5em] flex items-center justify-center">
        <div className="w-16 sm:w-20 md:w-24 text-right">
          <span ref={ref} className="inline-block min-w-[2ch] sm:min-w-[3ch]"></span>
        </div>
        <span className="w-4 sm:w-6 md:w-8 text-left">{suffix}</span>
      </div>
      <div className="text-xs sm:text-sm text-gray-600 mt-1 sm:mt-2 w-full text-center">
        {name}
      </div>
    </div>
  );
};

const Divider = () => (
  <div className="self-stretch w-[1px] sm:w-[2px] md:w-[3px] bg-gradient-to-b from-[#FA9173] to-[#30459E] mx-1 sm:mx-2 md:mx-3 rounded-[1px] sm:rounded-[2px]"></div>
);

function StatsSection() {
  const stats = [
    { name: "Blockchains", value: 6, suffix: "" },
    { name: "Charts", value: 600, suffix: "+" },
    { name: "Data processed", value: 50, suffix: "TB+" },
  ];

  return (
    <div className="bg-white text-black py-6 sm:py-8 md:py-12 lg:py-16">
      <div className="container mx-auto px-2 sm:px-4">
        <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-2 sm:mb-3 md:mb-4 font-monda">
          We have Got You Covered for Blockchain Data
        </h2>
        <p className="text-center mb-4 sm:mb-6 md:mb-8 lg:mb-12 text-gray-600 max-w-3xl mx-auto text-xs sm:text-sm md:text-base">
          Monitor Crypto Movements Instantly: See Fund Shifts, Identify New
          Projects, and Trace Transactions with 15-Second Node to Website Delay
          and Block-Level Precision.
        </p>
        <div className="flex justify-center">
          <div className="flex items-stretch">
            {stats.map((stat, index) => (
              <React.Fragment key={stat.name}>
                <Stat
                  num={stat.value}
                  suffix={stat.suffix}
                  name={stat.name}
                  decimals={stat.name === "Data processed" ? 1 : 0}
                />
                {index < stats.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatsSection;