import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// the metadata that is programmatic exists in chart.js this is static page metadata 
const defaultMetadata = {
  title: 'Wevr.ai - Data your way',
  description: 'Discover the future of data-driven insights with wevr.ai, the premier Web3 blockchain data analysis platform. Our cutting-edge solutions empower businesses to harness the true potential of decentralized networks, enabling smarter decision-making and optimized strategies.',
};

const pageMetadata = {
  '/Pricing': {
    title: 'Pricing | Wevr.ai',
    description: 'Explore our flexible pricing options for blockchain data analysis.',
  },
  '/Faq': {
    title: 'FAQ | Wevr.ai',
    description: 'Find answers to frequently asked questions about Wevr.ai and our services.',
  },
  '/Discover': {
    title: 'Discover | Wevr.ai',
    description: 'Stay updated with the latest news and insights in the blockchain, data analytics and Web3 space.',
  },
  '/Explore/Tables': {
    title: 'Data Tables | Wevr.ai',
    description: 'Explore comprehensive blockchain data in tabular format. Analyze and compare key metrics across various blockchains and tokens.',
  },
  // Add more pages as needed
};

function MetadataProvider({ children }) {
  const [metadata, setMetadata] = useState(defaultMetadata);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  useEffect(() => {
    const updateMetadata = () => {
      const pathname = window.location.pathname;
      setCurrentPath(pathname);
      
      if (pathname.startsWith('/Explore')) {
        if (pathname === '/Explore/Tables') {
          setMetadata(pageMetadata['/Explore/Tables']);
        } else {
          // Do nothing for other chart pages, let the Chart component handle it
          return;
        }
      } else {
        // Use pageMetadata if defined, otherwise use defaultMetadata
        setMetadata(pageMetadata[pathname] || defaultMetadata);
      }
    };

    updateMetadata();

    // Set up an interval to check for path changes
    const intervalId = setInterval(() => {
      if (window.location.pathname !== currentPath) {
        updateMetadata();
      }
    }, 100);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [currentPath]);

  // Render Helmet for all pages except chart pages (excluding /Explore/Tables)
  if (currentPath.startsWith('/Explore') && currentPath !== '/Explore/Tables') {
    return children;
  }

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href="./Assets/favicon.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />

        {/* Twitter Card data */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@wevr_ai" />
        <meta name="twitter:title" content={metadata.title} />
        <meta name="twitter:description" content={metadata.description} />
        <meta name="twitter:image" content="https://api.wevr.ai/V2/assets/WevrTwitterCard.png" />

        {/* Open Graph data */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://wevr.ai${currentPath}${window.location.search}`} />
        <meta property="og:title" content={metadata.title} />
        <meta property="og:description" content={metadata.description} />
        <meta property="og:image" content="https://api.wevr.ai/V2/assets/WevrTwitterCard.png" />
        <meta property="og:locale" content="en_AU" />
      </Helmet>
      {children}
    </HelmetProvider>
  );
}

export default MetadataProvider;