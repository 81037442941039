import React, { memo, useMemo } from "react";
import { motion } from "framer-motion";
import { LineChart, BarChart2, Bell } from "lucide-react";
// import BlockchainAnalytics from "../../Assets/chart.gif"; seeing if gif is problem
import BlockchainAnalytics from "../../Assets/chart-section.png";

import CustomQueries from "../../Assets/pairs_created.png";
import RealTimeMonitoring from "../../Assets/nft-wevr.png";

const features = [
  {
    title: "Comprehensive Blockchain Analytics",
    description:
      "Wevr.ai delivers powerful analytics across multiple blockchain networks, including Bitcoin, Ethereum, Base, Gnosis, Optimism, and Linear.",
    bullets: [
      "Advanced charting: Visualize complex on-chain data trends",
      "Detailed blockchain tables: Gain granular insights into every on-chain activity",
      "Multi-network analysis: Compare data across different blockchains",
    ],
    conclusion:
      "Our platform empowers traders, researchers, analysts, and developers to make data-driven decisions in the fast-paced world of cryptocurrencies and DeFi.",
    imageUrl: BlockchainAnalytics,
    imageAlt: "Blockchain analytics dashboard",
    icon: LineChart,
  },
  {
    title: "Custom Queries and Footprint Analysis",
    description:
      "Dive deep into blockchain data with our advanced research tools.",
    bullets: [
      "Custom Query Builder: Craft tailored blockchain queries and extract specific data points",
      "Footprint Analysis: Uncover the impact and reach of addresses, contracts, and tokens",
      "Fund flow tracking: Map out intricate webs of blockchain interactions",
    ],
    conclusion:
      "These tools enable everything from academic research and market intelligence to regulatory compliance and fraud detection, keeping you ahead in the evolving blockchain landscape.",
    imageUrl: CustomQueries,
    imageAlt: "Custom query and footprint analysis interface",
    icon: BarChart2,
  },
  {
    title: "Real-Time Monitoring and Custom Dashboards",
    description:
      "Stay on top of the dynamic crypto market with our real-time tools.",
    bullets: [
      "Live blockchain tracking: Get up-to-the-minute insights on network health and transactions",
      "Customizable dashboards: Consolidate your most important metrics in one view",
      "Alerts and notifications: Set up custom alerts for significant blockchain events",
    ],
    conclusion:
      "From traders and investors to developers and researchers, our platform provides the real-time data needed to make informed decisions in the fast-moving world of cryptocurrencies.",
    imageUrl: RealTimeMonitoring,
    imageAlt: "Real-time blockchain monitoring dashboard",
    icon: Bell,
  },
];

const BackgroundGrid = memo(() => (
  <div
    style={{
      backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgb(15 23 42 / 0.04)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e")`,
    }}
    className="absolute inset-0 z-0"
  >
    <div
      style={{
        backgroundImage:
          "radial-gradient(at 15% 50%, rgba(255,255,255,0) 0, rgba(255,255,255,1) 60%, rgba(255,255,255,1) 100%)",
      }}
      className="absolute inset-0"
    />
  </div>
));
BackgroundGrid.displayName = "BackgroundGrid";

const IconBadge = memo(({ Icon }) => (
  <div className="rounded-md bg-primary/10 p-2 ring-1 ring-primary/20">
    <Icon className="h-6 w-6 text-primary" />
  </div>
));
IconBadge.displayName = "IconBadge";

const FeatureImage = memo(({ src, alt }) => (
  <div className="lg:w-1/2 overflow-hidden rounded-xl">
    <img
      src={src}
      alt={alt}
      className="w-full h-auto object-cover"
      loading="lazy"
    />
  </div>
));
FeatureImage.displayName = "FeatureImage";

const FeatureContent = memo(({ feature }) => (
  <div className="lg:w-1/2 lg:mt-0 mt-8">
    <div className="flex items-center mb-4">
      <IconBadge Icon={feature.icon} />
      <h3 className="text-2xl font-bold tracking-tight text-gray-900 font-monda ml-3">
        {feature.title}
      </h3>
    </div>
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, ease: "easeOut" }}
      className="mt-6 text-base leading-7 text-gray-600 space-y-4"
    >
      <p className="font-medium text-gray-900">{feature.description}</p>
      <ul className="list-disc list-inside space-y-2">
        {feature.bullets.map((bullet, i) => (
          <li key={i}>{bullet}</li>
        ))}
      </ul>
      <p className="italic">{feature.conclusion}</p>
    </motion.div>
  </div>
));
FeatureContent.displayName = "FeatureContent";

const Feature = memo(({ feature, index }) => (
  <div
    className={`flex flex-col ${
      index % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"
    } items-center mt-24 gap-x-8 gap-y-16`}
  >
    <FeatureImage src={feature.imageUrl} alt={feature.imageAlt} />
    <FeatureContent feature={feature} />
  </div>
));
Feature.displayName = "Feature";

const ZigzagFeaturesSection = () => {
  const memoizedFeatures = useMemo(() => features, []);

  return (
    <div className="relative bg-white py-24 sm:py-32 overflow-hidden">
      <BackgroundGrid />
      <div className="relative z-10 mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-primary">
            Wevr.ai Features
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl font-monda">
            Unlock the Power of Blockchain Data
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Dive deep into on-chain and off-chain analytics with Wevr.ai. Our
            tools provide unparalleled insights into blockchain networks,
            empowering you in the world of crypto and DeFi.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          {memoizedFeatures.map((feature, index) => (
            <Feature key={feature.title} feature={feature} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(ZigzagFeaturesSection);
