import React, { memo, useMemo } from "react";
import { useBlogPosts } from "./API/useBlogPosts";
import defaultImage from "../../Assets/CL-May-Week2.png";

// Fallback data
const fallbackPosts = [
  {
    id: 1,
    title: "What is Erc20 ?",
    description:
      "Wevr Ai what is ERC20? overview of what ERC-20 token standard is.",
    date: "June 2, 2024",
    author: { name: "XBTOlivia", role: "Author" },
    category: { title: "ERC20", href: "/category/ERC20" },
    href: "/what-is-erc20",
    imageData: defaultImage,
  },
  {
    id: 2,
    title: "What is Bigdata?",
    description:
      "Wevr Ai what is Bigdata? An overview of Big data and how it is related to web3/",
    date: "June 1, 2024",
    author: { name: "XBTOlivia", role: "Author" },
    category: { title: "Educate", href: "/category/Educate" },
    href: "/what-is-bigdata",
    imageData: defaultImage,
  },
  {
    id: 3,
    title: "What is an Airdrop?",
    description:
      "Wevr Ai What is an airdrop? an overview of Airdrops - the benefits and cons .",
    date: "May 23, 2024",
    author: { name: "XBTOlivia", role: "Author" },
    category: { title: "Educate", href: "/category/Educate" },
    href: "/what-is-an-airdrop",
    imageData: defaultImage,
  },
];

const BlogPost = memo(({ post, isLoading }) => (
  <article className="flex flex-col items-start justify-between">
    <div className="relative w-full">
      <a href={`https://blog.wevr.ai/posts${post.href}`} className="block">
        <div className="aspect-[16/9] w-full overflow-hidden rounded-2xl bg-gray-100">
          {isLoading ? (
            <div className="animate-pulse bg-gray-200 h-full w-full" />
          ) : (
            <img
              src={post.imageData || defaultImage}
              alt={post.title}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultImage;
              }}
              className="h-full w-full object-cover object-center"
              loading="lazy"
              width="400"
              height="225"
            />
          )}
        </div>
        <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
      </a>
    </div>
    <div className="max-w-xl">
      <div className="mt-8 flex items-center gap-x-4 text-xs">
        <time dateTime={post.datetime} className="text-gray-500">
          {post.date}
        </time>
        <a
          href={`https://blog.wevr.ai/posts${post.category.href}`}
          className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
        >
          {post.category.title}
        </a>
      </div>
      <div className="group relative">
        <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
          <a href={`https://blog.wevr.ai/posts${post.href}`}>
            <span className="absolute inset-0" />
            {post.title}
          </a>
        </h3>
        <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
          {post.description}
        </p>
      </div>
      <div className="relative mt-8 flex items-center gap-x-4">
        <img
          alt={`${post.author.name}'s avatar`}
          src={`https://avatar.vercel.sh/${post.author.name}`}
          className="h-10 w-10 rounded-full bg-gray-100"
          loading="lazy"
          width="40"
          height="40"
        />
        <div className="text-sm leading-6">
          <p className="font-semibold text-gray-900">
            <a href={`https://blog.wevr.ai/posts${post.author.href || ""}`}>
              <span className="absolute inset-0" />
              {post.author.name}
            </a>
          </p>
          <p className="text-gray-600">{post.author.role}</p>
        </div>
      </div>
    </div>
  </article>
));
BlogPost.displayName = "BlogPost";

const BlogSection = () => {
  const { data: posts, isLoading, isError } = useBlogPosts();

  const displayPosts = useMemo(
    () => (isError ? fallbackPosts : posts || fallbackPosts),
    [posts, isError]
  );

  return (
    <section className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            <a href="https://blog.wevr.ai/">From the blog</a>
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Unlock blockchain insights with our expert on-chain research and
            analysis.
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {displayPosts.map((post) => (
            <BlogPost key={post.id} post={post} isLoading={isLoading} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default memo(BlogSection);
