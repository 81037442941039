import { cn } from "../../../utils/utils2";
import { AnimatedBeam } from "../../Ui/animatedbeam";
import React, { forwardRef, useRef } from "react";
import BaseLogo from "../../../Assets/base.svg";
import BitcoinLogo from "../../../Assets/bitcoin-1.svg";
import LineaLogo from "../../..//Assets/linea.svg";
import EthereumLogo from "../../../Assets/ethereum1.svg";
import GnosisLogo from "../../../Assets/gnosis.svg";
import ClickhouseLogo from "../../../Assets/clickhouse.svg";
import optimismLogo from "../../../Assets/optimism.svg";

const Circle = forwardRef(function Circle({ className, children }, ref) {
  return (
    <div
      ref={ref}
      className={cn(
        "z-10 flex h-12 w-12 items-center justify-center rounded-full border-2 bg-white p-3 ",
        className
      )}
    >
      {children}
    </div>
  );
});

export default function AnimatedBeamDemo() {
  const containerRef = useRef(null);
  const div1Ref = useRef(null);
  const div2Ref = useRef(null);
  const div3Ref = useRef(null);
  const div4Ref = useRef(null);
  const div5Ref = useRef(null);
  const div6Ref = useRef(null);
  const div7Ref = useRef(null);

  return (
    <div
      className="relative flex w-full max-w-[500px] items-center justify-center overflow-hidden rounded-lg "
      ref={containerRef}
    >
      <div className="flex h-full w-full flex-col items-stretch justify-between gap-10">
        <div className="flex flex-row items-center justify-between">
          <Circle ref={div1Ref}>
            <Icons.base />
          </Circle>
          <Circle ref={div5Ref}>
            <Icons.bitcoin />
          </Circle>
        </div>
        <div className="flex flex-row items-center justify-between">
          <Circle ref={div2Ref}>
            <Icons.linea />
          </Circle>
          <Circle ref={div4Ref} className="h-16 w-16">
            <Icons.clickhouse />
          </Circle>
          <Circle ref={div6Ref}>
            <Icons.ethereum />
          </Circle>
        </div>
        <div className="flex flex-row items-center justify-between">
          <Circle ref={div3Ref}>
            <Icons.gnosis />
          </Circle>
          <Circle ref={div7Ref}>
            <Icons.optimism />
          </Circle>
        </div>
      </div>

      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div1Ref}
        toRef={div4Ref}
        curvature={-75}
        endYOffset={-10}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div2Ref}
        toRef={div4Ref}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div3Ref}
        toRef={div4Ref}
        curvature={75}
        endYOffset={10}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div5Ref}
        toRef={div4Ref}
        curvature={-75}
        endYOffset={-10}
        reverse
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div6Ref}
        toRef={div4Ref}
        reverse
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div7Ref}
        toRef={div4Ref}
        curvature={75}
        endYOffset={10}
        reverse
      />
    </div>
  );
}

const Icons = {
  base: () => <img src={BaseLogo} alt="Base Logo" width="100" height="100" />,
  bitcoin: () => (
    <img src={BitcoinLogo} alt="Bitcoin Logo" width="100" height="100" />
  ),
  linea: () => (
    <img src={LineaLogo} alt="Linea Logo" width="100" height="100" />
  ),
  ethereum: () => (
    <img src={EthereumLogo} alt="Ethereum Logo" width="100" height="100" />
  ),
  gnosis: () => (
    <img src={GnosisLogo} alt="Gnosis Logo" width="100" height="100" />
  ),
  clickhouse: () => (
    <img src={ClickhouseLogo} alt="Clickhouse Logo" width="100" height="100" />
  ),
  optimism: () => (
    <img src={optimismLogo} alt="Zora Logo" width="100" height="100" />
  ),
};
